<template>
  <div class="admin-view-container compensation-view" v-loading="loading">
    <div class="admin-table-search">
      <h3>
        科目一 <span class="search-font">{{ detailData.sub1Num }}</span> 单，科目二
        <span class="search-font">{{ detailData.sub2Num }}</span> 单，科目三
        <span class="search-font">{{ detailData.sub3Num }}</span> 单
      </h3>
    </div>

    <div class="admin-table-list">
      <el-table :data="tableData" border height="100%">
        <el-table-column label="姓名" prop="drvStudentName"></el-table-column>
        <el-table-column label="身份证号" prop="drvStudentIdentityCard" width="200"></el-table-column>
        <el-table-column label="补偿科目" prop="compensateSub" :formatter="$formatter.dict($dict.examSub)"></el-table-column>
        <el-table-column label="补偿凭证" prop="compensateReceipt">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="$dict.SETTING.API_URL + '/image/' + scope.row.compensateReceipt"
              :preview-src-list="[$dict.SETTING.API_URL + '/image/' + scope.row.compensateReceipt]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="提交金额" prop="compensateAmount"></el-table-column>
        <el-table-column label="结算金额" prop="compensateAmount">
          <template slot-scope="scope">
            <span v-if="scope.row.compensateApplyStatus == 3">
              {{ scope.row.compensateAmount }}
            </span>
            <span v-else-if="scope.row.compensateApplyStatus == 4">0</span>
            <span v-else> -- </span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="creationTime" :formatter="$formatter.date(true)"></el-table-column>
        <el-table-column label="审核状态" prop="compensateApplyStatus">
          <template slot-scope="scope">
            <span v-if="scope.row.compensateApplyStatus == 1" style="color: #ff9919">待审核</span>
            <span v-if="scope.row.compensateApplyStatus == 2" style="color: #0075ff">审核中</span>
            <span v-if="scope.row.compensateApplyStatus == 3" style="color: #34b377">已通过</span>
            <span v-if="scope.row.compensateApplyStatus == 4" style="color: #ff422d">
              未通过
              <el-tooltip class="item" effect="light" :content="scope.row.auditReasons" placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <admin-space class="admin-table-links">
              <el-link
                type="primary"
                @click="deleteHandler(scope.row)"
                v-if="scope.row.compensateApplyStatus == 1 || scope.row.compensateApplyStatus == 4">
                删除
              </el-link>
              <span v-else>--</span>
            </admin-space>
          </template>
        </el-table-column>
      </el-table>

      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="total"
          :page-size="tableQuery.size"
          :current-page="tableQuery.page"
          :layout="'total, sizes, prev, pager, next, jumper'"
          background></el-pagination>
      </div>
    </div>

    <div class="admin-dialog-btns">
      审核通过{{ detailData.passNum }}单 赔付金额:<span style="color: red">
        ￥{{ detailData.compensateAmountTotal || '0.00' }}
      </span>
      <el-tooltip class="item" effect="light" :content="dateTips" placement="top-start" v-if="isOverDate">
        <el-button type="primary" class="disabled-button">确认打款</el-button>
      </el-tooltip>
      <el-button type="primary" @click="submitHandler" v-else>确认打款</el-button>
    </div>
  </div>
</template>

<script>
import {
  schDrvCompensateApplyGetList,
  schDrvCompensateApplyGetApplyDetails,
  schDrvCompensateApplyDelApply,
  schDrvCompensateApplySubmitApply,
  sysConfigGetCompensation,
} from '@/api/index';
export default {
  data() {
    return {
      loading: false,
      tableQuery: {
        page: 1,
        size: 20,
      },
      detailData: {
        sub1Num: '', // 科一单数
        sub2Num: '',
        sub3Num: '',
        passNum: '', // 审核通过单数
        compensateAmountTotal: '', // 预计补偿
      },
      tableData: [],
      total: 0,
      dateTips: '',
      isOverDate: false,
    };
  },
  created() {
    this.getPostDate();
    this.getData();
  },
  methods: {
    getCompensationListMsg() {
      this.loading = true;
      schDrvCompensateApplyGetApplyDetails().then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.detailData.sub1Num = res.data.data.sub1Num;
          this.detailData.sub2Num = res.data.data.sub2Num;
          this.detailData.sub3Num = res.data.data.sub3Num;
          this.detailData.passNum = res.data.data.passNum;
          this.detailData.compensateAmountTotal = res.data.data.compensateAmountTotal;
          if (!res.data.data.passNum) {
            this.isOverDate = true;
            this.dateTips = '暂无可提交赔付';
          }
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    deleteHandler(row) {
      this.$adminConfirm('用以永久删除该数据').then(() => {
        this.loading = true;
        this.$utils.resolveRequest(
          schDrvCompensateApplyDelApply(row),
          this,
          () => {
            this.getData();
          },
          () => {
            this.loading = false;
          },
        );
      });
    },

    getPostDate() {
      this.loading = true;
      sysConfigGetCompensation().then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.dateTips = '未到提交周期，提交周期为每月' + res.data.data.sysConfigValue + '日';
          let arr = res.data.data.sysConfigValue.split('-');
          arr = arr.sort(function (a, b) {
            return a - b;
          });
          let nowDate = new Date().getDate();
          if (nowDate >= arr[0] && nowDate <= arr[1]) {
            this.isOverDate = false;
          } else {
            this.isOverDate = true;
          }
        } else {
          this.dateTips = '暂时无法提交。';
          this.isOverDate = true;
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    getData() {
      this.loading = true;
      let postData = this.tableQuery;
      schDrvCompensateApplyGetList(postData).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.getCompensationListMsg();
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    // 提交补偿
    submitHandler() {
      if (this.tableQuery.page == 1 && !this.tableData.length) {
        this.$message.error('暂时不可提交，目前暂无统筹单！');
        return false;
      }
      schDrvCompensateApplySubmitApply({}).then((res) => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.tableQuery.page = 1;
          this.getData();
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    // 每页数量切换
    handleSizeChange(val) {
      this.tableQuery.page = 1;
      this.tableQuery.size = val;
      this.getData();
    },
    // 页数切换
    handleCurrentChange(val) {
      this.tableQuery.page = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss">
.compensation-view {
  .admin-table-search {
    .search-font {
      color: $--color-primary;
    }
  }
  .admin-dialog-btns {
    font-size: 18px;
  }
  .disabled-button {
    cursor: not-allowed;
    background-color: #80baff;
    border-color: #80baff;
  }
}
</style>
